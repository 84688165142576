.social-icons {
  justify-content: space-around;
}

.social-icons a {
  margin: 0 5px 10px;
}

@media (min-width: 1600px) {
  html {
    font-size: 1rem;
  }
}

@keyframes boxActive {
  0% {
    box-shadow: 0 0 10px 3px rgba(72,72,72,.15);
  }

  10% {
    box-shadow: 0 0 10px 5px rgba(233,81,29,.75);
  }

  90% {
    box-shadow: 0 0 10px 5px rgba(233,81,29,.75);
  }

  100% {
    box-shadow: 0 0 10px 3px rgba(72,72,72,.15);
  }
}

.dhsv_detailedrequest_form {
  height: calc(100vh - 140px);
  border-radius: 5px;
  box-shadow: 0 0 10px 3px rgba(72,72,72,.25);
  background-color: #fff;
}

.dhsv_detailedrequest_form .header {
  display: flex;
  flex-direction: row;
  background-color: #677f70;
  color: #fff;
  padding: 0 0 0 2rem;
}

.dhsv_detailedrequest_form .header>.wrapper {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
}

.dhsv_detailedrequest_form .header .step {
  padding: 0 4rem;
  height: 100px;
  display: flex;
  align-items: center;
  opacity: .4;
}

.dhsv_detailedrequest_form .header .step b {
  margin-right: 10px;
}

.dhsv_detailedrequest_form .header .step.active {
  opacity: 1;
  font-weight: 600;
}

.dhsv_detailedrequest_form .header .header-right {
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}

.dhsv_detailedrequest_form .header .header-right span {
  cursor: pointer;
  font-size: 2rem;
}

.dhsv_detailedrequest_form .body .dhsv_form {
  padding: 2rem;
  height: calc(100vh - 240px);
  overflow: auto;
  background-color: none;
}

.dhsv_detailedrequest_form .body .dhsv_form .h3 {
  color: #677f70;
  font-weight: 600;
}

.dhsv_detailedrequest_form button[type=submit].back {
  background-color: rgba(0,0,0,0);
  color: #e9511d;
}

.dhsv_detailedrequest_form button[type=submit].back .material-icons {
  margin-right: 16px;
  margin-top: -2px;
}

.dhsv_detailedrequest_form.step-1 div.start-of-lease .left {
  padding-right: 0;
  margin-top: 13px;
}

.dhsv_detailedrequest_form.step-2 form.dhsv_form button[type=submit] {
  margin: 2rem auto 1rem;
}

.dhsv_detailedrequest_form.step-3 .header {
  background-color: #e9511d;
}

.dhsv_detailedrequest_form.step-3 .body {
  text-align: center;
  background: linear-gradient(180deg, white 0%, #f7f3ed 100%);
}

.dhsv_detailedrequest_form.step-3 img.aoc-logo {
  max-width: 230px;
  margin-top: 55px;
  margin-bottom: 60px;
}

.dhsv_detailedrequest_form.step-3 .social-icons {
  display: block;
}

.dhsv_detailedrequest_form.step-3 .social-icons a svg path {
  fill: #000;
}

.dhsv_detailedrequest_form.step-3 hr {
  border-width: 2px;
}

.dhsv_detailedrequest_form.step-3 p {
  color: #84949b;
}

@media (max-width: 1199.98px) {
  .dhsv_detailedrequest_form {
    height: calc(100vh - 130px);
  }

  .dhsv_detailedrequest_form .header {
    padding: 0;
  }

  .dhsv_detailedrequest_form .header .step {
    padding: 0 2rem;
  }

  .dhsv_detailedrequest_form .body .dhsv_form {
    height: calc(100vh - 230px);
  }

  .dhsv_detailedrequest_form .body .dhsv_form button[type=submit] {
    margin: 2rem auto;
  }

  .dhsv_detailedrequest_form .body .dhsv_form button.back {
    position: absolute;
    top: 3rem;
    left: 1rem;
  }
}

@media (max-width: 991.98px) {
  .dhsv_detailedrequest_form {
    height: calc(100vh - 110px);
  }

  .dhsv_detailedrequest_form .header {
    padding: 0;
  }

  .dhsv_detailedrequest_form .header .step {
    padding: 0 1rem;
    height: 60px;
    text-align: center;
    font-size: .7rem;
    flex: 1;
  }

  .dhsv_detailedrequest_form .header .step span {
    width: 100%;
    display: block;
  }

  .dhsv_detailedrequest_form .header .step b {
    font-size: 1.5rem;
    margin-right: 0;
    display: block;
  }

  .dhsv_detailedrequest_form .header .header-right {
    width: 60px;
    height: 60px;
    line-height: 60px;
  }

  .dhsv_detailedrequest_form .header .header-right span {
    font-size: 1.5rem;
  }

  .dhsv_detailedrequest_form .body {
    overflow: auto;
    height: calc(100% - 60px);
  }

  .dhsv_detailedrequest_form .body>.row {
    margin: 0;
  }

  .dhsv_detailedrequest_form .body>.row>div {
    padding: 0;
  }

  .dhsv_detailedrequest_form .body .dhsv_form {
    height: auto;
    min-height: calc(100vh - 170px);
  }
}

@media (max-width: 575.98px) {
  .dhsv_detailedrequest_form {
    height: calc(100vh - 50px);
  }

  .dhsv_detailedrequest_form .body {
    padding-bottom: 5rem;
  }
}

.dhsv_detailedrequest_form .field-numberOfPersons:not(.select--is-disabled) {
  animation: boxActive 4s ease-in-out;
}

