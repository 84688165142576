@import './../../../../styles/common/variables';
@import '~bootstrap/scss/mixins/breakpoints';

@keyframes boxActive {
  0% {
    box-shadow: 0 0 10px 3px rgba($grey, 0.15);
  }

  10% {
    box-shadow: 0 0 10px 5px rgba($secondary, 0.75);
  }

  90% {
    box-shadow: 0 0 10px 5px rgba($secondary, 0.75);
  }

  100% {
    box-shadow: 0 0 10px 3px rgba($grey, 0.15);
  }
}

.dhsv_detailedrequest_form {
  height: calc(100vh - 140px);
  border-radius: 5px;
  box-shadow: 0 0 10px 3px rgba($grey, 0.25);
  background-color: white;

  .header {
    display: flex;
    flex-direction: row;
    background-color: $primary-shaded;
    color: white;
    padding: 0 0 0 $space-sm;

    > .wrapper {
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: center;
    }

    .step {
      padding: 0 $space-md;
      height: 100px;
      display: flex;
      align-items: center;
      opacity: 0.4;

      b {
        margin-right: 10px;
      }

      &.active {
        opacity: 1;
        font-weight: 600;
      }
    }

    .header-right {
      width: 100px;
      height: 100px;
      line-height: 100px;
      text-align: center;

      span {
        cursor: pointer;
        font-size: 2rem;
      }
    }
  }

  .body {
    .dhsv_form {
      padding: $space-sm;
      // 80px header + 60px padding + 100px form header
      height: calc(100vh - 240px);
      overflow: auto;
      background-color: none;

      .h3 {
        color: $primary-shaded;
        font-weight: 600;
      }
    }
  }

  button[type='submit'].back {
    background-color: transparent;
    color: $secondary;

    .material-icons {
      margin-right: 16px;
      margin-top: -2px;
    }
  }

  &.step-1 {
    div.start-of-lease .left {
      padding-right: 0;
      margin-top: 13px;
    }
  }

  &.step-2 {
    form.dhsv_form {
      button[type='submit'] {
        margin: $space-sm auto $space-xs;
      }
    }
  }

  &.step-3 {
    .header {
      background-color: $secondary;
    }

    .body {
      text-align: center;
      background: linear-gradient(180deg, white 0%, $grey-white 100%);
    }

    img.aoc-logo {
      max-width: 230px;
      margin-top: 55px;
      margin-bottom: 60px;
    }

    .social-icons {
      display: block;

      a svg path {
        fill: black;
      }
    }

    hr {
      border-width: 2px;
    }

    p {
      color: $grey-light;
    }
  }

  @include media-breakpoint-down(lg) {
    height: calc(100vh - 130px);

    .header {
      padding: 0;

      .step {
        padding: 0 $space-sm;
      }
    }

    .body {
      .dhsv_form {
        height: calc(
          100vh - 230px
        ); // 70px header + 60px padding + 100px form header

        button[type='submit'] {
          margin: 2rem auto;
        }

        button.back {
          position: absolute;
          top: 3rem;
          left: $space-xs;
        }
      }
    }
  }

  @include media-breakpoint-down(md) {
    height: calc(100vh - 110px);

    .header {
      padding: 0;

      .step {
        padding: 0 $space-xs;
        height: 60px;
        text-align: center;
        font-size: 0.7rem;
        flex: 1;

        span {
          width: 100%;
          display: block;
        }

        b {
          font-size: 1.5rem;
          margin-right: 0;
          display: block;
        }
      }

      .header-right {
        width: 60px;
        height: 60px;
        line-height: 60px;

        span {
          font-size: 1.5rem;
        }
      }
    }

    .body {
      overflow: auto;
      height: calc(100% - 60px);

      > .row {
        margin: 0;

        > div {
          padding: 0;
        }
      }

      .dhsv_form {
        height: auto;
        min-height: calc(
          100vh - 170px
        ); // 50px header + 60px padding + 60px form header
      }
    }
  }

  @include media-breakpoint-down(xs) {
    height: calc(100vh - 50px);

    .body {
      padding-bottom: 5rem;
    }
  }

  .field-numberOfPersons:not(.select--is-disabled) {
    animation: boxActive 4s ease-in-out;
  }
}
